import { useOrderedSections, Visibility } from "../../hooks";
import {
  AutoFillStatus,
  FieldItem,
  GroupItem,
  ResponseSubmitter,
  SectionItem,
  SubmissionItem,
  TeamItem,
} from "../../types";
import { IntakeSummary } from "../summary";

export type IntakeFormSummaryProps = {
  sections: SectionItem[];
  groups: GroupItem[];
  fields: FieldItem[];
  onSelect: (section: SectionItem) => void;
  submitResponses: ResponseSubmitter;
  setStatus: (status: SubmissionItem["status"]) => void;
  team: TeamItem;
  sidebar: boolean;
  responses: Record<string, any>;
  isLoadingDBResponses: boolean;
  visibility: Visibility;
  disabled?: boolean;
  previewOptions?: {
    previewResponses: Record<string, any>;
    clearLastPreviewSection: () => void;
    hidePreviewErrors: boolean;
  };
  autoFillingStatus?: AutoFillStatus;
};

export const IntakeFormSummary = ({
  sections,
  groups,
  fields,
  onSelect,
  submitResponses,
  setStatus,
  team,
  sidebar,
  responses,
  isLoadingDBResponses,
  visibility,
  disabled,
  previewOptions,
  autoFillingStatus,
}: IntakeFormSummaryProps) => {
  const orderedSections = useOrderedSections({
    sections,
    groups,
    fields,
    visibility,
  });
  const onSubmit = () => {
    if (previewOptions) previewOptions.clearLastPreviewSection();
    const status = "completed";
    submitResponses({ status, responses: {} })
      .then((result) => {
        if (result?.operationIds) setStatus(status);
      })
      .catch(() => {});
  };

  return (
    <IntakeSummary
      isForm={!disabled}
      team={team}
      onSelect={onSelect}
      onSubmit={onSubmit}
      onSubmitButton={disabled ? onSubmit : undefined}
      visibleSections={orderedSections}
      sidebar={sidebar}
      responses={previewOptions?.previewResponses || responses}
      isLoadingDBResponses={isLoadingDBResponses}
      hidePreviewErrors={previewOptions?.hidePreviewErrors}
      autoFillingStatus={autoFillingStatus}
    />
  );
};
