import { differenceInHours } from "date-fns";
import { useEffect, useState } from "react";

import {
  aiFillSectionUri,
  defaultAIFillSettings,
  FormAIFillSettings,
  SubmissionStatus,
} from "@smart/bridge-types-basic";
import { entriesOf, fromEntries, mapEntries } from "@smart/itops-utils-basic";

import { useDBResponses } from "./database-responses";
import { useFileUpload } from "./file-upload";
import { useLoadMatterFields } from "./load-matter-fields";
import { useVisibility } from "./visibility";
import { useSectionVisit } from "./visit";
import {
  AutoFillStatus,
  ConnectionItem,
  FieldItem,
  GroupItem,
  LoadResponses,
  ResponseSubmitter,
  SectionItem,
  SubmissionItem,
  SubmissionStatusItem,
  ToastContent,
} from "../types";

const LinkSaveCopyTTLInHours = 24;

export type IntakeFormHookProps = {
  submission: SubmissionItem;
  sections: SectionItem[];
  groups: GroupItem[];
  fields: FieldItem[];
  connection?: ConnectionItem;
  disabled?: boolean;
  submitResponses: ResponseSubmitter;
  loadResponses: LoadResponses;
  shouldLoadDBResponses?: boolean;
  postSubmissionStatus?: SubmissionStatusItem;
  previewLastSection?: [
    SectionItem | "summary" | undefined,
    React.Dispatch<React.SetStateAction<SectionItem | "summary" | undefined>>,
  ];
  isPreview?: boolean;
  aiFillSettings?: FormAIFillSettings;
  runAutoFilling?: (uploadedFileKeys: string[]) => Promise<void>;
  resetAutoFilling?: () => void;
  autoFillStatus?: AutoFillStatus;
  submitAutofillFeedback?: (feedback?: string) => Promise<void>;
};

export const useIntakeForm = ({
  submission,
  sections,
  fields,
  groups,
  connection,
  disabled,
  submitResponses,
  loadResponses,
  shouldLoadDBResponses,
  postSubmissionStatus,
  previewLastSection,
  isPreview,
  aiFillSettings,
  runAutoFilling,
  resetAutoFilling,
  autoFillStatus,
  submitAutofillFeedback,
}: IntakeFormHookProps) => {
  const [summary, setSummary] = useState(previewLastSection?.[0] === "summary");
  const [responses, setResponses] = useState(() =>
    submission.responses
      ? mapEntries<Record<string, { value: any }>, Record<string, any>>(
          submission.responses,
          ({ value }) => value,
        )
      : {},
  );
  const [status, setStatus] = useState<SubmissionStatus | undefined>(
    submission.status || "active",
  );

  const uploadFileProps = useFileUpload();
  const visibility = useVisibility({ sections, groups, fields, responses });
  const visibleSections = sections
    .filter((s) => !!visibility.visibleSections[s.uri])
    .map((section) => ({
      ...section,
      hasRequired: !!fields.find(
        (f) => f.sectionUri === section.uri && f.mandatory,
      ),
    }));
  const autoFillSection: SectionItem | undefined =
    aiFillSettings?.allowAiFill && visibleSections.length > 0
      ? {
          uri: aiFillSectionUri,
          title:
            aiFillSettings.sectionTitle || defaultAIFillSettings.sectionTitle,
        }
      : undefined;

  const [sidebar, setSidebar] = useState(
    isPreview ? false : visibleSections.length > 1 || !!autoFillSection,
  );

  const [selected, setSelected] = useState<SectionItem>(
    (submission.lastUpdatedSectionUri &&
      visibleSections.find(
        (s) => s.uri === submission.lastUpdatedSectionUri,
      )) ||
      (previewLastSection?.[0] !== "summary" && previewLastSection?.[0]) ||
      autoFillSection ||
      visibleSections[0],
  );
  const [sectionToast, setSectionToast] = useState<ToastContent | undefined>();

  const {
    saveTimestampAndSubmit,
    databaseResponses,
    isLoading: isLoadingDBResponses,
  } = useDBResponses({
    submitResponses,
    loadResponses,
    shouldLoad: !!shouldLoadDBResponses && summary,
  });

  const {
    isUpdating,
    isAllVisited,
    isSectionVisited,
    isSectionVisiting,
    setSectionVisited,
    setSectionVisiting,
    isNavigatable,
  } = useSectionVisit({
    selected,
    visibleSections,
    visibleFields: fields.filter((f) => !!visibility.visibleFields[f.uri]),
    lastUpdatedSectionUri: submission.lastUpdatedSectionUri,
    responses,
  });

  const onSelect = (section: SectionItem) => {
    setSectionVisiting(section);
    setSummary(false);
    setSelected(section);
    if (previewLastSection) previewLastSection[1](section);
  };

  const onSummary = () => {
    setSummary(true);
    if (previewLastSection) previewLastSection[1]("summary");
  };

  const onRunAutoFilling = async (fileKeys: string[]) => {
    if (!runAutoFilling) return;

    try {
      await runAutoFilling(fileKeys);
    } catch (error) {
      console.error("Error running auto filling. ", error);
    }
  };

  let printUnavailable: string | undefined;
  if (!disabled && (status !== "completed" || !!submission.deleted)) {
    printUnavailable = "Saving a copy of the form is not available.";
  } else if (
    !!submission.updatedAt &&
    differenceInHours(new Date(), new Date(submission.updatedAt)) >
      LinkSaveCopyTTLInHours
  ) {
    printUnavailable = `Saving a copy of the form is not available after ${LinkSaveCopyTTLInHours} hours.`;
  }

  useLoadMatterFields(
    {
      submissionSyncStatus: submission.syncStatus,
      fn: () => {
        if (submission.responses) {
          setResponses((currentResponses) => {
            const loadedResponses = fromEntries(
              entriesOf(submission.responses!)
                .filter(
                  ([fieldUri, { syncStatus }]) =>
                    !currentResponses[fieldUri] && syncStatus === "loaded",
                )
                .map(([fieldUri, { value }]) => [fieldUri, value]),
            );

            return {
              ...currentResponses,
              ...loadedResponses,
            };
          });
        }
      },
    },
    [submission.responses],
  );

  useEffect(() => {
    if (autoFillStatus === "completed") {
      setSectionToast({
        text: "Autofill complete",
        type: "success",
      });
    } else {
      setSectionToast(undefined);
    }

    if (autoFillStatus && selected.uri === aiFillSectionUri) {
      onSelect(visibleSections[0]);
    }
  }, [autoFillStatus]);

  return {
    status: postSubmissionStatus?.status || status,
    summary,
    statusProps: {
      status: postSubmissionStatus?.status || status,
      deleted:
        postSubmissionStatus?.deleted !== undefined
          ? postSubmissionStatus?.deleted
          : submission.deleted,
      autoFillStatus,
      onSubmitAutofillFeedback: submitAutofillFeedback,
    },
    sidebarProps: {
      autoFillSection,
      visibleSections,
      selected,
      onSelect,
      summary,
      onSummary,
      sidebar,
      setSidebar,
      isUpdating,
      isAllVisited,
      isNavigatable,
      isSectionVisited,
      isSectionVisiting,
      isAutoFillSectionSelected: selected.uri === aiFillSectionUri,
      autoFillStatus,
    },
    summaryProps: {
      connection,
      sections,
      groups,
      fields,
      onSelect,
      submitResponses: saveTimestampAndSubmit,
      isLoadingDBResponses,
      setStatus,
      sidebar,
      responses: shouldLoadDBResponses ? databaseResponses : responses,
      visibility,
      disabled,
      autoFillStatus,
    },
    sectionProps: {
      key: selected?.uri,
      connection,
      autoFillSection,
      visibleSections,
      isUpdating,
      selected,
      groups,
      fields,
      onSelect,
      onSummary,
      submitResponses: saveTimestampAndSubmit,
      setResponses,
      setStatus,
      setVisited: setSectionVisited,
      sidebar,
      responses,
      visibility,
      disabled,
      autoFillStatus,
      toast: sectionToast,
      closeToast: () => setSectionToast(undefined),
    },
    printProps: {
      content: {
        sections,
        groups,
        fields,
        visibility,
        responses,
      },
      disabled: disabled || autoFillStatus === "filling",
      unavailable: printUnavailable,
    },
    uploadFileProps,
    autoFillProps: {
      autoFillSection,
      sidebar,
      description:
        aiFillSettings?.fileUploadDescription ||
        defaultAIFillSettings.fileUploadDescription,
      isAutoFillSectionSelected: selected.uri === aiFillSectionUri,
      autoFillStatus,
      disabled,
      onSkip: () => {
        if (sections.length) onSelect(sections[0]);
        if (resetAutoFilling) resetAutoFilling();
      },
      onSubmit: onRunAutoFilling,
      onReset: () => {
        if (resetAutoFilling) resetAutoFilling();
      },
    },
  };
};
