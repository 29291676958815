import {
  defaultTemplate,
  templateResponsePlaceholders,
} from "@smart/bridge-templates-basic";
import { ContactDisplay, Displayer } from "@smart/itops-ui-dom";

import { AutofillFeedback } from "./autofill-components";
import { IntakePrintProps, useIntakePrint } from "./print-components";
import { Page, Section, PageWrapper } from "./section-components";
import { TeamLabel } from "./shared-components/team-label";
import { AutoFillStatus, SubmissionItem, TeamItem } from "../types";

export type IntakeStatusProps = {
  team: TeamItem;
  hideTeam?: boolean;
  response?: string;
  category?: string;
  status?: SubmissionItem["status"];
  deleted?: boolean | null;
  printOptions?: IntakePrintProps;
  autoFillStatus?: AutoFillStatus;
  onSubmitAutofillFeedback?: (feedback?: string) => Promise<void>;
};

export const IntakeStatus = ({
  team,
  hideTeam,
  response,
  category,
  status,
  deleted,
  printOptions,
  autoFillStatus,
  onSubmitAutofillFeedback,
}: IntakeStatusProps) => {
  const { onPrint, elements } = useIntakePrint(printOptions);
  let statusText = "has been completed";
  if (status === "cancelled") statusText = "has been closed";
  if (status === "draft") statusText = "is not currently available";
  if (deleted) statusText = "has been removed";

  return (
    <PageWrapper sidebar={false}>
      <Page>
        {!hideTeam && (
          <TeamLabel teamName={team.name} teamPicture={team.picture} />
        )}
        <Section data-testid="status-content">
          {!deleted && status === "completed" && (
            <Displayer
              paragraphTag="p"
              value={response}
              fallback={defaultTemplate.response}
              placeholders={{
                keys: templateResponsePlaceholders,
                data: {
                  downloadFormLink: "#download",
                  firmName: team.name,
                  firmPhone: team.phone || "",
                  matterCategory: category || "matter",
                },
              }}
              onPlaceholderClick={(e, element) => {
                if (element.name === "downloadFormLink") {
                  e.preventDefault();
                  onPrint();
                }
              }}
            />
          )}
          {(deleted || status !== "completed") && (
            <p>
              This form {statusText}. Please{" "}
              <ContactDisplay
                link={(team.email && `mailto:${team.email}`) || undefined}
                text={team.name || "us"}
              />{" "}
              if you have any further questions.
            </p>
          )}
          <AutofillFeedback
            open={autoFillStatus === "completed"}
            onSubmit={onSubmitAutofillFeedback}
          />
        </Section>
      </Page>
      {elements}
    </PageWrapper>
  );
};
