import styled from "@emotion/styled";
import { useCallback, useRef, useState } from "react";

import { bytesPerMegaByte } from "@smart/bridge-types-basic";
import { Icon } from "@smart/itops-icons-dom";
import { Modal } from "@smart/itops-smokeball-components-dom";
import {
  FileFieldItem,
  FileInputField,
  FileUpload,
  UploadFileProps,
  UploadStatus,
} from "@smart/itops-ui-dom";
import { unique } from "@smart/itops-utils-basic";

import { ErrorDisplay } from "./error";
import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";
import { LookupOptions } from "../../types";

const defaultMaxNumOfFiles = 5;
const defaultFileSizeLimitMegaByte = 128;

export const defaultSizeLimit = `Max ${defaultMaxNumOfFiles} files; Max ${defaultFileSizeLimitMegaByte} MB each.`;

const ExceedMaxFiles = styled.div`
  font-size: ${(props) => props.theme.fontSize.base};

  .alert-icon {
    color: ${(props) => props.theme.palette.warning.base};
    margin-right: 0.5rem;
  }
`;

export type FileFieldValue = {
  fileName: string;
  downloadUrl: string;
  uploadStatus: UploadStatus;
  key?: string;
};

export const FileField = ({
  field,
  index,
  innerRef,
  value,
  error,
  loading,
  disabled,
  hideLabel,
  onChange,
  onBlur,
  fileLookup: { load, upload },
  uploadFileProps,
  maxNumOfFiles,
  fileSizeLimitMegaByte,
  supportedFileTypes,
}: FieldProps<HTMLInputElement, FileFieldValue[]> & {
  fileLookup: LookupOptions["fileLookup"];
  maxNumOfFiles?: number;
  fileSizeLimitMegaByte?: number;
  supportedFileTypes?: { name: string; extension: string }[];
} & { uploadFileProps: UploadFileProps }) => {
  const maxFilesModal = useState(false);
  const [exceedMaxFiles, setExceedMaxFiles] = useState(false);
  const maxNumOfFilesToUse = maxNumOfFiles || defaultMaxNumOfFiles;
  const fileSizeLimitToUse =
    fileSizeLimitMegaByte || defaultFileSizeLimitMegaByte;
  const buildSuportFileTypesInfo = () => {
    if (!supportedFileTypes?.length) return "";
    const suportedFileTypeNames = unique(supportedFileTypes.map((f) => f.name));
    const last = suportedFileTypeNames.pop() || "";
    const info =
      suportedFileTypeNames.length > 0
        ? `${suportedFileTypeNames.join(", ")} and ${last}; `
        : last;
    return `${info.charAt(0).toLocaleUpperCase()}${info.slice(1)}`;
  };
  const supportFileTypesInfo = buildSuportFileTypesInfo();
  const sizeLimit = `Max ${maxNumOfFilesToUse} files; ${supportFileTypesInfo}Max ${fileSizeLimitToUse} MB each.`;
  const acceptedFileTypes = supportedFileTypes?.length
    ? supportedFileTypes.map((f) => f.extension).join(",")
    : undefined;

  // We use a ref to keep the latest files instead of relaying on the value passed in
  // so we can update (e.g changing uploadStatus) in between rendering cycles and not get outdated data.
  const filesRef = useRef<
    (FileFieldValue & { file?: File; uploadUrl?: string })[]
  >(
    // For backwards compatibility
    (value as unknown as Omit<FileFieldValue, "uploadStatus">)?.downloadUrl
      ? [
          {
            ...(value as unknown as Omit<FileFieldValue, "uploadStatus">),
            uploadStatus: "uploaded",
          },
        ]
      : value || [],
  );

  const fieldWrapperRef = useRef<HTMLDivElement | null>(null);

  const scrollIntoFieldWrapper = () => {
    if (fieldWrapperRef.current && fieldWrapperRef.current.scrollIntoView) {
      fieldWrapperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const updateFieldValue = () =>
    onChange(
      filesRef.current.map(({ fileName, downloadUrl, uploadStatus, key }) => ({
        fileName,
        downloadUrl,
        uploadStatus,
        key,
      })),
    );

  const removeInvalidFiles = () => {
    filesRef.current = filesRef.current.filter(
      ({ uploadStatus }) =>
        uploadStatus !== "exceedSizeLimit" && uploadStatus !== "failedToUpload",
    );
  };

  const addFiles = (files: File[]) => {
    filesRef.current = [
      ...filesRef.current,
      ...files.map((file) => ({
        fileName: file.name,
        downloadUrl: "",
        uploadUrl: "",
        uploadStatus:
          file.size > fileSizeLimitToUse * bytesPerMegaByte
            ? ("exceedSizeLimit" as const)
            : ("notUploaded" as const),
        file,
      })),
    ];
    updateFieldValue();
  };

  const id = fieldName({ field, index });
  const errorId = fieldName({ field, index, suffix: "error" });

  const onSelect = useCallback(
    async (fileList?: FileList | null) => {
      if (!fileList?.length) return;

      removeInvalidFiles();

      if (filesRef.current.length >= maxNumOfFilesToUse) {
        maxFilesModal[1](true);
        return;
      }
      const files = Array.from(fileList);
      const filesToAdd =
        filesRef.current.length + files.length > maxNumOfFilesToUse
          ? files.slice(0, maxNumOfFilesToUse - filesRef.current.length)
          : files;
      if (filesToAdd.length < files.length) setExceedMaxFiles(true);

      addFiles(filesToAdd);

      let result: Awaited<ReturnType<typeof load>>;
      try {
        result = await load({
          fileNames: files.map((f) => f.name),
          fieldUri: field.uri,
        });
        scrollIntoFieldWrapper();
      } catch (e) {
        console.error(e);
      }

      filesRef.current = filesRef.current.map((fileValue, i) => {
        // We don't need to update existing files
        const offsetIndex = filesRef.current.length - filesToAdd.length;
        if (i < offsetIndex) return fileValue;

        const resultIndex: number = i - offsetIndex;
        return {
          ...fileValue,
          downloadUrl: result?.[resultIndex].downloadUrl || "",
          uploadUrl: result?.[resultIndex].uploadUrl || "",
          uploadStatus:
            !result?.[resultIndex].uploadUrl &&
            fileValue.uploadStatus !== "exceedSizeLimit"
              ? "failedToUpload"
              : fileValue.uploadStatus,
          key: result?.[resultIndex].key,
        };
      });

      updateFieldValue();
      onBlur();
    },
    [field.uri],
  );

  return (
    <FieldWrapper
      aria-disabled={disabled}
      ref={fieldWrapperRef}
      isLoading={loading}
    >
      <Label {...field} index={index} hideLabel={hideLabel} />
      <FileUpload
        id={id}
        error={error}
        errorId={errorId}
        onSelect={onSelect}
        sizeLimit={sizeLimit}
      />
      {value &&
        (Array.isArray(value)
          ? value
          : [
              {
                ...(value as Omit<FileFieldValue, "uploadStatus">),
                uploadStatus: "uploaded" as const,
              },
            ]
        ).map((file, i) => (
          <FileFieldItem
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            fileName={file.fileName}
            downloadUrl={file.downloadUrl}
            uploadUrl={filesRef.current[i]?.uploadUrl}
            file={filesRef.current[i]?.file}
            uploadStatus={file.uploadStatus}
            upload={upload}
            fileIndex={i}
            fieldId={id}
            uploadFileProps={uploadFileProps}
            fileSizeLimitMegaByte={fileSizeLimitToUse}
            maxNumOfFiles={maxNumOfFilesToUse}
            onStatusChange={(status: UploadStatus) => {
              filesRef.current = filesRef.current.map((f, fileIndex) =>
                i === fileIndex ? { ...f, uploadStatus: status } : f,
              );
              updateFieldValue();
              onBlur();
            }}
            onRemove={() => {
              const toUpdate = [...filesRef.current];
              toUpdate.splice(i, 1);
              filesRef.current = toUpdate;
              setExceedMaxFiles(false);
              updateFieldValue();
              onBlur();
            }}
          />
        ))}
      {exceedMaxFiles && (
        <ExceedMaxFiles>
          <Icon className="alert-icon" name="alert" size={15} />
          Some files could not be uploaded. Max number of files are{" "}
          {maxNumOfFilesToUse}.
        </ExceedMaxFiles>
      )}
      <FileInputField
        className="file-value"
        ref={innerRef}
        id={id}
        name={id}
        aria-invalid={!!error}
        aria-errormessage={error ? errorId : undefined}
        type="file"
        data-testid="file-upload"
        multiple
        accept={acceptedFileTypes}
        onChange={async (e) => {
          e.preventDefault();
          onSelect(e.target.files).catch(console.error);
        }}
      />
      <Modal
        title=" "
        subtitle="No more files can be uploaded"
        subtitleIcon="information"
        open={maxFilesModal}
        isForm={false}
        buttons={[
          {
            text: "Ok",
            type: "button",
            onClick: () => maxFilesModal[1](false),
          },
        ]}
        closeOptions={{
          closeButton: false,
          escapeKey: false,
          clickOutside: false,
        }}
      >
        <p>The maximum number of files has been reached.</p>
      </Modal>
      <ErrorDisplay uri={field.uri} index={index} error={error} />
    </FieldWrapper>
  );
};
