import styled from "@emotion/styled";
import { useState } from "react";

import { documentAutomation } from "@smart/bridge-images-dom";
import { Button, Modal, TextArea } from "@smart/itops-ui-dom";

import { InputItem } from "../field-components/item";

const FeedbackWrapper = styled.div`
  display: flex;

  img {
    margin: 1rem;
    height: 12rem;
    width: 12rem;
    flex: 0 0 12rem;
  }

  .feedback {
    margin-left: 1rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-size: ${(props) => props.theme.fontSize.heading};
        margin: 2rem 0 0 0;
      }

      button {
        color: ${(props) => props.theme.palette.foreground.accent};
      }
    }

    .content {
      margin-right: 2rem;

      h3 {
        margin-top: 2rem;
      }

      button {
        margin: 2rem 0;
      }
    }
  }
`;

type AutofillFeedbackProps = {
  open: boolean;
  onSubmit?: (feedback?: string) => Promise<void>;
};

export const AutofillFeedback = ({ open, onSubmit }: AutofillFeedbackProps) => {
  const [openModal, setOpenModal] = useState(open);
  const [accuracy, setAccuracy] = useState<string>();
  const [expectation, setExpectation] = useState<string>();
  const [other, setOther] = useState<string>();

  const accuracyOptions = [
    {
      label: "Very accurately",
      value: "Filling my form by AI is very accurate",
    },
    {
      label: "Somewhat accurately",
      value: "Filling my form by AI is somewhat accurate",
    },
    {
      label: "Somewhat inaccurately",
      value: "Filling my form by AI is somewhat incaccurate",
    },
    {
      label: "Very inaccurately",
      value: "Filling my form by AI is very inaccurate",
    },
    {
      label: "I'm not sure",
      value: "I have no feedback on accuracy",
    },
  ];
  const expectationOptions = [
    {
      label: "Yes",
      value: "Autofill met my expectations",
    },
    {
      label: "No",
      value: "Autofill did not meet my expectations",
    },
    {
      label: "Somewhat",
      value: "Autofill somehwat met my expectations",
    },
    {
      label: "I had no expectations",
      value:
        "I had no expectations on how the autofill assistant fills out my form",
    },
  ];

  const submitFeedback = () => {
    if (!onSubmit) return;

    const combinedFeedback = [accuracy, expectation, other].filter(
      (value) => !!value,
    );
    const feedbackToSubmit =
      combinedFeedback.length > 0 ? combinedFeedback.join(". ") : undefined;

    onSubmit(feedbackToSubmit).catch((error) =>
      console.error("Error submitting autofill feedback. ", error),
    );

    setOpenModal(false);
  };

  const closeFeedback = async () => {
    if (onSubmit) {
      await onSubmit();
    }
    setOpenModal(false);
  };

  return (
    <Modal open={openModal} onClose={closeFeedback} size={{ width: "tablet" }}>
      <FeedbackWrapper>
        <img src={documentAutomation} alt="Autofill feedback" />
        <div className="feedback">
          <div className="header">
            <h2>Autofill Assistant</h2>
            <Button
              className="modal-close"
              icon={{ library: "lucide", name: "X" }}
              kind="borderless"
              aria-label="Close Modal - X"
              variant="plain"
              onClick={closeFeedback}
            />
          </div>
          <div className="content">
            <h3>
              How accurately did the Autofill Assistant fill out your form?
            </h3>
            {accuracyOptions.map(({ value, label }) => (
              <InputItem
                key={value}
                uri={value}
                type="radio"
                label={label}
                value={value}
                checked={accuracy === value}
                onChange={(v) => setAccuracy(v || undefined)}
              />
            ))}
            <h3>Did the Autofill Assistant meet your expectations?</h3>
            {expectationOptions.map(({ value, label }) => (
              <InputItem
                key={value}
                uri={value}
                type="radio"
                label={label}
                value={value}
                checked={expectation === value}
                onChange={(v) => setExpectation(v || undefined)}
              />
            ))}
            <h3>Please provide any other feedback</h3>
            <TextArea
              value={other}
              onChange={(e) => setOther(e.target.value)}
              minHeight={12}
              size="base"
              placeholder="Type your feedback so we can continue to improve your experience"
            />
            <Button
              text="Submit feedback"
              type="button"
              name="submit"
              value="feeback"
              onClick={() => submitFeedback()}
              variant="save"
              size="base"
              disabled={!accuracy && !expectation && !other}
            />
          </div>
        </div>
      </FeedbackWrapper>
    </Modal>
  );
};
