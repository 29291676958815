import styled from "@emotion/styled";
import { MouseEvent, ReactNode } from "react";

import { Icon } from "@smart/itops-icons-dom";
import { Button, FailureMessage } from "@smart/itops-ui-dom";

import { TeamItem } from "../../types";

const SectionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .buttons {
    display: flex;
    flex-flow: row wrap;
    gap: ${(props) => props.theme.baseUnit}rem;
  }

  .edit-button {
    border: none;
    background: none;
    text-decoration: underline;
    color: ${(props) => props.theme.palette.primary.base};
    cursor: pointer;
  }
`;

export type SectionButtonsProps = {
  onPrevious?: (e: MouseEvent<HTMLButtonElement>) => void;
  onNext?: (e: MouseEvent<HTMLButtonElement>) => void;
  onSubmit?: (e: MouseEvent<HTMLButtonElement>) => void;
  hasError?: string;
  isForm: boolean;
  team: TeamItem;
  disabled?: {
    back?: boolean;
    next?: boolean;
    submit?: boolean;
  };
  children: ReactNode;
};

export const SectionButtons = ({
  onPrevious,
  onNext,
  onSubmit,
  hasError,
  team,
  isForm,
  disabled,
  children,
}: SectionButtonsProps) => (
  <SectionButtonsWrapper>
    {hasError && (
      <FailureMessage
        action="submit form"
        contactName={team.name || "us"}
        contactLink={(team.email && `mailto:${team.email}`) || undefined}
      />
    )}
    <div className="buttons">
      {onPrevious && (
        <Button
          text="Back"
          variant="navigation"
          left={<Icon library="lucide" name="MoveLeft" />}
          onClick={onPrevious}
          kind="borderless"
          size="base"
          disabled={disabled?.back}
        />
      )}
    </div>
    {children}
    <div className="buttons">
      {onNext && (
        <Button
          text="Next"
          type={isForm ? "submit" : "button"}
          name="submit"
          value="next"
          onClick={onNext}
          variant="save"
          size="base"
          disabled={disabled?.next}
        />
      )}
      {onSubmit && (
        <Button
          text="Submit"
          variant="save"
          type={isForm ? "submit" : "button"}
          name="submit"
          value="submit"
          onClick={onSubmit}
          disabled={disabled?.submit}
          size="base"
        />
      )}
    </div>
  </SectionButtonsWrapper>
);
