import { z } from "zod";

import { AIUserFeedbackSchema } from "@smart/bridge-types-basic";
import { buildApiGatewaySchemas } from "@smart/itops-constructs-basic";

import { apiGateway } from "./api-gateway";
import {
  addressPredictionSchema,
  addressSchema,
  loadedFormSchema,
} from "./shared-schemas";

const submissionStatusSchema = z.enum([
  "draft",
  "active",
  "completed",
  "cancelled",
]);

export const createSubmissionLambdaSchema = {
  input: z.object({
    encodedParam: z.string().optional(),
    teamId: z.string().optional(),
    slugId: z.string().optional(),
  }),
  output: z.object({
    slug: z
      .object({
        uri: z.string(),
      })
      .nullable(),
    submission: z
      .object({
        uri: z.string(),
      })
      .nullable(),
    analyticsTools: z
      .array(z.object({ type: z.string(), trackingId: z.string() }))
      .optional(),
    operationId: z.string().optional(),
  }),
};

export const loadLambdaSchema = {
  input: z.object({
    slug: z.string().optional(),
    id: z.string(),
  }),
  output: z.object({
    form: loadedFormSchema.optional(),
    team: z
      .object({
        uri: z.string(),
        name: z.string(),
        email: z.string().optional(),
        address: z.string(),
        phone: z.string(),
        source: z.string(),
        picture: z.string().optional(),
      })
      .optional(),
    submission: z
      .object({
        uri: z.string(),
        teamUri: z.string(),
        responses: z.record(
          z.object({
            value: z.any(),
            updatedAt: z.string(),
          }),
        ),
        status: submissionStatusSchema.optional(),
        lastUpdatedFieldUri: z.string().optional(),
        lastUpdatedSectionUri: z.string().optional(),
        aiFillFiles: z.array(z.string()).optional(),
        deleted: z.boolean().optional(),
        updatedAt: z.string().optional(),
      })
      .optional(),
    staffDetails: z
      .array(
        z.object({
          id: z.string(),
          firstName: z.string().optional().nullable(),
          middleName: z.string().optional().nullable(),
          lastName: z.string().optional().nullable(),
          busySlots: z
            .array(
              z.object({
                id: z.string(),
                startTime: z.string(),
                endTime: z.string(),
                timezone: z.string().optional().nullable(),
              }),
            )
            .optional(),
        }),
      )
      .optional(),
    aiUserFeedback: AIUserFeedbackSchema.optional(),
  }),
};

export const submitLambdaSchema = {
  input: z.object({
    submissionUri: z.string(),
    formUri: z.string(),
    teamUri: z.string(),
    lastUpdatedFieldUri: z.string().optional(),
    lastUpdatedSectionUri: z.string().optional(),
    status: submissionStatusSchema.optional(),
    responses: z.record(z.any()),
    aiFillFiles: z.array(z.string()).optional(),
    autofillFeedback: z.string().optional(),
  }),
  output: z.object({
    operationIds: z.array(z.string()),
    submissionStatus: z.object({
      status: submissionStatusSchema.optional(),
      deleted: z.boolean().optional(),
    }),
  }),
};

export const searchAddressLambdaSchema = {
  input: z.object({
    search: z.string(),
    sessionKey: z.string().optional(),
  }),
  output: z.object({
    addresses: z.array(addressPredictionSchema),
  }),
};

export const getAddressLambdaSchema = {
  input: z.object({
    placeId: z.string(),
    sessionKey: z.string().optional(),
  }),
  output: addressSchema,
};

export const uploadFileLambdaSchema = {
  input: z.object({
    fileNames: z.array(z.string()),
    submissionUri: z.string(),
    formUri: z.string(),
    fieldUri: z.string(),
    teamUri: z.string(),
  }),
  output: z.array(
    z.object({
      key: z.string(),
      downloadUrl: z.string(),
      uploadUrl: z.string(),
    }),
  ),
};

export const recaptchaLambdaSchema = {
  input: z.object({
    token: z.string(),
    siteKey: z.string(),
    expectedAction: z.string(),
  }),
  output: z.object({
    riskAnalysis: z.object({
      score: z.number(),
      reasons: z.array(z.string()),
    }),
  }),
};

export const getStaffLambdaSchema = {
  input: z.object({
    accountId: z.string(),
    id: z.string(),
  }),
  output: z.object({
    id: z.string().nullable(),
    userId: z.string().nullable(),
    firstName: z.string().nullable(),
    middleName: z.string().nullable(),
    lastName: z.string().nullable(),
    enabled: z.boolean(),
  }),
};

export const apiSchemas = buildApiGatewaySchemas(apiGateway, {
  createSubmission: createSubmissionLambdaSchema,
  load: loadLambdaSchema,
  submit: submitLambdaSchema,
  searchAddress: searchAddressLambdaSchema,
  getAddress: getAddressLambdaSchema,
  uploadFile: uploadFileLambdaSchema,
  recaptcha: recaptchaLambdaSchema,
  getStaff: getStaffLambdaSchema,
});
